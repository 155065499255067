import { Component, OnInit, ViewChild } from '@angular/core';
import { IonList, NavParams, PopoverController } from '@ionic/angular';
import { IonColor, NotificationColorService } from 'src/app/services/notification-color.service';


@Component({
  selector: 'app-color-picker-popover',
  templateUrl: './color-picker-popover.component.html',
  styleUrls: ['./color-picker-popover.component.scss'],
})
export class ColorPickerPopoverComponent implements OnInit {

  @ViewChild(IonList, { static: true }) list: IonList;

  private currentColor: IonColor;

  constructor(
    public colorService: NotificationColorService,
    private navParams: NavParams,
    private popCtrl: PopoverController) {
  }

  ngOnInit() {
    this.currentColor = this.navParams.get('color');
  }

  closePopover() {
    this.popCtrl.getTop().then(p => p.dismiss(this.currentColor))
  }

  selectColor(idx: number) {
    this.currentColor = this.colorService.colorList[idx];
    this.closePopover();
    // console.log(`Selected: ${idx} from ColorPickerPopoverPage`)
  }
}
