import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const CHAR_MAX = 150
    if(value.length < CHAR_MAX){
      return value
    }else{
      return value.substr(0, CHAR_MAX) + '...'
    }
  }

}
