import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dbDateToHumanDateOnlyDate'
})
export class DbDateToHumanDateOnlyDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const aux = moment(value)
    return aux.format('DD-MM-YYYY');
  }

}
