import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Place } from 'src/app/services/place-service';
import { Observable } from 'rxjs';
import { User } from 'parse';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as moment from 'moment'

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
})
export class PlaceCardComponent implements OnInit {

  @Input() place: Place;
  @Input() scrollObservable: Observable<any>;
  @Input() extraParams: any = {};
  @Input() color = 'white';
  @Input() showStatus: boolean;
  @Output() placeDeleted = new EventEmitter()

  constructor(
    private toastCtrl:ToastController,
    private router: Router,
    protected translate: TranslateService,
    private alertCtrl: AlertController,
    private placeService: Place) { }

  ngOnInit() {
    console.log('this.place', this.place);
    
  }

  getStatusColor(status: string) {
    if (status === 'Pending') {
      return 'warning';
    } else if (status === 'Approved') {
      return 'success';
    } else if (status === 'Rejected') {
      return 'danger';
    } else  {
      // console.log('no match found');
    }
  }

  async activatePlace(e){
    e.stopPropagation();
    this.showConfirm(await this.getTrans('ENABLE_PLACE_CONFIRM'), async () => {
      await this.placeService.enablePlace(this.place, this.place.user);
    });
  }

  async showConfirm(message: string, onPressOK?: any, onPressCancel?: any) {

    const trans = await this.getTrans(['OK', 'CANCEL']);

    const confirm = await this.alertCtrl.create({
      header: '',
      message: message,
      buttons: [{
        text: trans.CANCEL,
        role: 'cancel',
        handler: onPressCancel
      }, {
        text: trans.OK,
        role: '',
        handler: onPressOK
      }]
    });

    return await confirm.present();
  }
  
  getTrans(key: string | string[], params: any = {}) {
    return this.translate.get(key, params).toPromise();
  }

  showEnablePlaceButton(){

    const NO_MOSTRAR = false
    const SI_MOSTRAR = true

    if(this.place.status !== 'Expired'){
      return NO_MOSTRAR
    }

    if(this.place.user.proPlan.status !== 'active'){
      return NO_MOSTRAR
    }
    
    if(this.place.isPrivate===false){      
      return SI_MOSTRAR
    }

    if(this.place.isCapacityAndPayment!==true){
      return SI_MOSTRAR
    }else{

      if(this.isPlaceExpireByDob()===true){
        return NO_MOSTRAR
      }else{
        return SI_MOSTRAR
      }

    }

  }

  isPlaceExpireByDob(){
    if(this.place.dob===''){
      return false
    }
    const expireTime = moment(this.place.dob)
    const now = moment()
    if(expireTime.unix() < now.unix()){
      return true
    }else{
      return false
    }

  }

  async deletePlace(e, place){
    e.stopPropagation()
    this.showConfirm(await this.getTrans('EXPIRE_PLACE_CONFIRM'), async (res) => {
      this.placeService.deleteEND(place).then((resDelete)=>{
        console.log('resDelete',resDelete)
        this.placeDeleted.emit(this.place)
      }).catch(async err=>{
        this.showToast(await this.getTrans('ERROR_DELETING_PLACE'))
        console.log('err',err)
      })
    });    

  }

  async showToast(message: string) {

    const closeText = await this.translate.get('CLOSE').toPromise();

    const toast = await this.toastCtrl.create({
      message: message,
      color: 'dark',
      position: 'bottom',
      duration: 3000,
      showCloseButton: true,
      closeButtonText: closeText,
    });

    return await toast.present();
  }

  
}
