import { RabbitmqService } from './services/rabbitmq.service';
import { Component } from '@angular/core';
import { Platform, Events, AlertController, ToastController, LoadingController, ModalController, Config, NavController } from '@ionic/angular';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import * as Parse from 'parse';
import { LocalStorage } from './services/local-storage';
import { User } from './services/user-service';
import { WindowRef } from './services/window-ref';
import { Router, NavigationEnd } from '@angular/router';
import { Preference } from './services/preference';
import { Category } from './services/categories';
import { Slide } from './services/slide';
import { Place } from './services/place-service';
import { Review } from './services/review-service';
import { Post } from './services/post';
import { OneSignalService } from './services/one-signal.service';
import { WalkthroughPage } from './pages/walkthrough/walkthrough';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  status = 'ONLINE';
  isConnected = true;

  private user: User;
  private objWindow: any;
  private loader: any;
  private currentUrl: string;

  private reloadUser = null
  private activatedPro = null
  private canceledPro = null

  constructor(
    private connectionService: ConnectionService,
    public rabbitmqService: RabbitmqService,
    private platform: Platform,
    private router: Router,
    private storage: LocalStorage,
    private preference: Preference,
    private events: Events,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private userService: User,
    private windowRef: WindowRef,
    private oneSignalService: OneSignalService,
    private headerColor: HeaderColor,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private deeplinks: Deeplinks,
    private mobileAccessibility: MobileAccessibility) {

    this.initializeApp();
  }

  ngOnInit() {
  }

  checkConnection(){
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        setTimeout(() => {
          this.rabbitmqService.startRabbit(this.user.id)
          console.log('online detected and startRabbit');
        }, 2000);

      }
      else {
        this.status = "OFFLINE";
        console.log('offline detected and startRabbit');

        this.rabbitmqService.stopRabbit()
      }
    })
  }

  checkMaintenance(){

      let query = new Parse.Query("Maintenance");
      
      query.descending("createdAt")

      query.limit(1)
      query.find().then(data => {
        console.log('Maintenance', data);
        const status = data[0].get('status')
        console.log('status',status);
        if(status === 'on'){
          this.router.navigate(['/mantenaince'])
        }else if (status === 'off'){
          this.router.navigate(['/1'])
        }
      })
  }

  initRabbit() {
    const channel = User.getCurrent().id
    console.log('channel', channel)
    this.rabbitmqService.startRabbit(channel)

    setTimeout(() => {
      this.rabbitmqService.startRabbit(channel)
    }, 10000);

    this.rabbitmqService.maintenance.subscribe(data=>{
      // alert('desde app component ' + data)
      if(data==='on'){
        this.router.navigate(['/mantenaince'])
      }else if (data === 'off'){
        this.router.navigate(['/1'])
      }
    })

    if (this.reloadUser === null) {
      this.reloadUser = this.rabbitmqService.reloadUser.subscribe(async data => {
        this.loadCurrentUser()
        console.log('User.getCurrent()', User.getCurrent())
      })
    }
    if (this.activatedPro === null) {
      this.activatedPro = this.rabbitmqService.activatedPro.subscribe(async data => {
        console.log('activatedPro');
        const IMPORTANT = await this.translate.get('IMPORTANT').toPromise();
        const PRO_ACCOUNT_HAS_BEEN_ACTIVATED = await this.translate.get('PRO_ACCOUNT_HAS_BEEN_ACTIVATED').toPromise();

        this.showAlert(IMPORTANT, PRO_ACCOUNT_HAS_BEEN_ACTIVATED)
      })
    }
    if (this.canceledPro === null) {
      this.canceledPro = this.rabbitmqService.canceledPro.subscribe(async data => {
        console.log('canceledPro');
        const IMPORTANT = await this.translate.get('IMPORTANT').toPromise();
        const PRO_ACCOUNT_HAS_BEEN_DEACTIVATED = await this.translate.get('PRO_ACCOUNT_HAS_BEEN_DEACTIVATED').toPromise();

        this.showAlert(IMPORTANT, PRO_ACCOUNT_HAS_BEEN_DEACTIVATED).then(()=>{
          setTimeout(() => {
            this.router.navigate(["/"])
          }, 2000);
        })
      })
    }
  }

  async initializeApp() {


    const config = new Config;

    if (this.platform.is('desktop')) {
      config.set('rippleEffect', false);
      config.set('animated', false);
    }

    this.platform.ready().then(async () => {
      config.set('backButtonText', await this.translate.get('BACK').toPromise());
    });

    this.mobileAccessibility.usePreferredTextZoom(false);

    this.subscribeToRouterChanges();

    this.objWindow = this.windowRef.nativeWindow;

    this.setupDeeplinks();
    this.setupParse();
    this.setupDefaults();
    this.setupEvents();

    if (this.platform.is('cordova')) {
      await this.platform.ready();
      this.setupAndroidHeaderColor();
      this.setupStatusBar();
      this.splashScreen.hide();
    }
    this.checkMaintenance()
    this.checkConnection()
  }

  async setupDefaults() {

    this.translate.setDefaultLang(environment.defaultLang);

    try {
      let lang = await this.storage.getLang() || environment.defaultLang;

      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }

      await this.storage.setLang(lang);
      this.translate.use(lang);
      this.preference.lang = lang;
    } catch (error) {
      console.log(error);
    }

    try {
      const unit = await this.storage.getUnit() || environment.defaultUnit;
      await this.storage.setUnit(unit);
      this.preference.unit = unit;
    } catch (error) {
      console.log(error);
    }

    try {
      const home = await this.storage.getHome() || environment.defaultHome;
      await this.storage.setHome(home);
      this.preference.home = home;
    } catch (error) {
      console.log(error);
    }

  }

  subscribeToRouterChanges() {

    this.router.events.subscribe(async (event) => {

      if (event instanceof NavigationEnd) {

        // Show the Walkthrought Modal only if the user is in homepage
        // and no previous page exists.

        if (this.router.url === '/1/home' && !this.currentUrl) {

          try {

            const skipIntro = await this.storage.getSkipIntroPage();

            if (!skipIntro) {
              this.presentWalkthroughModal();
            }

          } catch (error) {
            console.log(error);
          }
        }

        this.currentUrl = this.router.url;
      }
    })

  }

  setupEvents() {

    this.events.subscribe('user:login', (user) => {
      console.log('login subscribe');
      console.log('user',user)
      console.log('user.id',user.id)
      // this.rabbitmqService.startRabbit(user.id) //no hace falta porque esta en el loadingUser

      this.user = user;
      this.loadCurrentUser();
      this.updateInstallation();
    });

    this.events.subscribe('user:logout', () => {
      this.onLogOut();
    });

    this.events.subscribe('notification:opened', (data: OSNotificationOpenedResult) => {
      const additionalData = data.notification.payload.additionalData
      console.log('events.subscribe', data);

      if (additionalData && additionalData.manualExpire === true) {
        const url = `/1/profile/places`
        this.navigateTo(url, { });
      }

      if (additionalData && additionalData.go_to_user_management) {
        const url = `/1/places/${additionalData.go_to_user_management}/${additionalData.slug}/likes`
        this.navigateTo(url, { go_to_user_management: true });
      }

      if (additionalData && additionalData.user_has_pay) {
        const url = `/1/places/${additionalData.user_has_pay}/${additionalData.slug}/likes`
        this.navigateTo(url, { go_to_user_management: true });
      }

      if (additionalData && additionalData.place_id) {
        this.navigateTo('/1/places/' + additionalData.place_id);
      }
      if (additionalData && additionalData.place_notification_id) {
        this.navigateTo('/1/notifications/place/' + additionalData.place_notification_id);
      }
      if (additionalData && additionalData.user_notification_id) {
        this.navigateTo('/1/notifications/user/' + additionalData.user_notification_id);
      }
    });
  }

  async loadCurrentUser() {
    console.log('LOGGGINNNN loadCurrentUser');

    this.user = User.getCurrent();
    if (this.user) {
      this.initRabbit()

      await this.user.fetch();
      this.updateInstallation();
    }
  }

  navigateTo(page: any, queryParams: any = {}) {
    this.router.navigate([page], { queryParams: queryParams });
  }

  setupParse() {
    Slide.getInstance();
    Post.getInstance();
    Review.getInstance();
    Place.getInstance();
    Category.getInstance();
    User.getInstance();

    Parse.initialize(environment.appId);
    (Parse as any).serverURL = environment.serverUrl;

    if (!this.platform.is('hybrid')) {
      // Load the Facebook API asynchronous when the window starts loading

      this.objWindow.fbAsyncInit = function () {
        Parse.FacebookUtils.init({
          appId: environment.fbId,
          cookie: true,
          xfbml: true,
          version: 'v1.0'
        });
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/all.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }

    this.loadCurrentUser();
  }

  setupStatusBar() {
    if (this.platform.is('ios')) {
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleLightContent();
    } else {
      this.statusBar.backgroundColorByHexString(environment.androidHeaderColor);
    }
  }

  setupAndroidHeaderColor() {
    if (environment.androidHeaderColor && this.platform.is('android')) {
      this.headerColor.tint(environment.androidHeaderColor);
    }
  }

  async setupDeeplinks() {
    const _self = this;
    this.platform.ready().then(() => {
      this.deeplinks.route({
        '/1/home/places/:id': this,
        '/1/home/places/:id/:slug': this,
        '/1/places/:id': this,
        '/1/places/:id/:slug': this,
        '/1/notifications/users/add/:username': this,
      }).subscribe(match => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);
        _self.navigateTo(match.$link.path);
      }, nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
    });
  }

  async updateInstallation() {

    try {
      const user = this.user ? this.user : null;

      if (user) {
        this.oneSignalService.sendTag('user_id', user.id);
      } else {
        this.oneSignalService.deleteTag('user_id');
      }
    } catch (error) {
      console.log(error);
    }

  }

  async presentWalkthroughModal() {

    await this.showLoadingView();

    const modal = await this.modalCtrl.create({
      component: WalkthroughPage
    });

    await modal.present();

    this.dismissLoadingView();
  }

  async showNotification(notification: any) {
    const trans = await this.translate.get(['NOTIFICATION', 'OK']).toPromise();
    this.showAlert(trans.NOTIFICATION, notification.alert, trans.OK);
  }

  async showAlert(title: string = '', message: string = '', okText: string = 'OK') {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [okText],
    });
    return await alert.present();
  }

  async showToast(message: string = '') {

    let alert = await this.toastCtrl.create({
      message: message,
      duration: 3000
    });

    return await alert.present();
  }

  async showLoadingView() {

    const loadingText = await this.translate.get('LOADING').toPromise();

    this.loader = await this.loadingCtrl.create({
      message: loadingText
    });

    return await this.loader.present();
  }

  async dismissLoadingView() {

    if (!this.loader) return;

    try {
      await this.loader.dismiss()
    } catch (error) {
      console.log('ERROR: LoadingController dismiss', error);
    }
  }

  async onLogOut() {
    this.rabbitmqService.stopRabbit()

    try {
      await this.showLoadingView();
      await this.userService.logout();
      this.events.publish('user:loggedOut');
      this.user = null;
      this.navigateTo('/');
      this.translate.get('LOGGED_OUT').subscribe(str => this.showToast(str));
      this.dismissLoadingView();
      this.updateInstallation();
    } catch (err) {
      console.log(err.message);
      this.dismissLoadingView();
    }

  }

}
