export const environment = {
  production: true,
  serverUrl: 'https://apiv2.yacubo.com/api',
  // serverUrl: 'https://api.yacubo.com/api',
  appUrl: 'https://webv2.yacubo.com',
  appImageUrl: 'https://webv2.yacubo.com/assets/icon/icon.png',
  appId: 'myAppId',
  fbId: '753685455092932',
  stripePublicKey: 'pk_test_5xmekF4Wvx58VA5MjDZxUevW00iUiYUcfg',
  oneSignalAppId: 'db52d831-24e7-4971-90a7-25e5afbc806a',
  googleProjectNumber: '160135562218',
  googleMapsApiKey: 'AIzaSyBWkpaYtMkPNYIyxbBtxRJWGz0AZ-kxzX4',
  androidHeaderColor: '#30408C',
  defaultUnit: 'km',
  defaultLang: 'es',
  defaultHome: 'home'
};
