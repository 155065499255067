import { Injectable, EventEmitter } from '@angular/core';
var Stomp = require('../../assets/js/stomp.js');

@Injectable({
  providedIn: 'root'
})
export class RabbitmqService {
  ws=null
  client = null
  reloadPlaces = new EventEmitter()
  reloadUser = new EventEmitter()
  activatedPro = new EventEmitter()
  canceledPro = new EventEmitter()
  maintenance = new EventEmitter()


  constructor() { }

  async stopRabbit(){
    console.log('STOPPING RABBIT MQ');
    // await this.ws.close()
    this.client.disconnect()
    
    setTimeout(() => {
      this.client=null
      this.ws = null
      console.log('this.ws',this.ws)
      console.log('this.client',this.client)
      
    }, 3000);
  }

  startRabbit(channel){
    // console.log('STARTING RABBIT MQ');
    // console.log('this.client',this.client)
    //   if(this.client!==null){
    //     return
    //   }
      
    //   this.ws = new WebSocket('ws://164.132.107.178:15674/ws');
    //   this.client = Stomp.Stomp.over(this.ws);
      
    //   var on_connect = (con) => {
    //     console.log('rabbit channel',channel)
    //     this.client.subscribe(channel, (data)=>{
    //         console.log('data',data)
    //         this.handleRabbitMsg(JSON.parse(data.body))
    //       })
    //   };

    //   var on_error =  (err) => {
    //     console.log('rabbit error',err);
    //     this.client.disconnect()
    //     setTimeout(() => {
    //       this.client=null
    //       this.ws = null
    //       this.startRabbit(channel)
    //       // alert('SE HA DESCONECTADO RABBIT, Y SE AUTOCONECTARA AUTOMATICAMENTE EN TEORIA' + channel)
    //     }, 5000);
    //   };
    //   this.client.debug = null
    //   this.client.connect('guest', 'ako8iewequ8oox7No4Ie', on_connect, on_error, "/");
  }

  handleRabbitMsg(msg){
    console.log('msg',msg)
    console.log('llegomensaje ' + msg.method)
    // alert('llegomensaje ' + msg.method)
    switch (msg.method) {
      case 'reloadUser':
        this.reloadUser.emit(msg)
        break;
      case 'reloadPlaces':
        this.reloadPlaces.emit(msg)
        break;
      case 'activatedPro':
        this.activatedPro.emit(msg)
        break;
      case 'canceledPro':
        console.log('canceledPro emmit');
        this.canceledPro.emit(msg)
        break;
      case 'maintenanceon':
        console.log('canceledPro emmit');
        this.maintenance.emit('on')
        break;
      case 'maintenanceoff':
        this.maintenance.emit('off')
        break;
      default:
        break;
    }
  }
}
