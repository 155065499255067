import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { InfoWindowComponent } from './info-window/info-window';
import { TranslateModule } from '@ngx-translate/core';
import { UploadBoxComponent } from './upload-box/upload-box.component';
import { PlaceCardComponent } from './place-card/place-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RatingModule } from 'ng-starrating';
import { ColorPickerPopoverComponent } from './color-popover/color-picker-popover.component';
import { PlaceHeaderComponent } from './place-header/place-header.component';
import { EllipsisPipe } from '../pipes/ellipsis.pipe'
import { DbDateToHumanDateOnlyDatePipe } from '../pipes/db-date-to-human-date-only-date.pipe';
import { DbDateToHumanDateOnlyHourPipe } from '../pipes/db-date-to-human-date-only-hour.pipe';
import { DbDateToHumanDatePipe } from '../pipes/db-date-to-human-date.pipe';

@NgModule({
	declarations: [
		DbDateToHumanDateOnlyDatePipe,
		DbDateToHumanDatePipe,
		DbDateToHumanDateOnlyHourPipe,
		EllipsisPipe,
		InfoWindowComponent,
		UploadBoxComponent,
		ColorPickerPopoverComponent,
		PlaceCardComponent,
		PlaceHeaderComponent
	],
	entryComponents: [
		InfoWindowComponent,
		ColorPickerPopoverComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		RouterModule,
		TranslateModule,
		LazyLoadImageModule,
		RatingModule,
	],
	exports: [
		EllipsisPipe,
		InfoWindowComponent,
		UploadBoxComponent,
		ColorPickerPopoverComponent,
		PlaceCardComponent,
		PlaceHeaderComponent
	],
})
export class ComponentsModule {}
