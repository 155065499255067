import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class User extends Parse.User {

  constructor() {
    super('_User');
  }

  static getInstance() {
    return this;
  }

  static getCurrent() {
    return User.current() as User;
  }

  isLoggedInViaFacebook() {
    return this.authData && this.authData.facebook;
  }

  create(data: any = {}): Promise<User> {
    return this.signUp(data);
  }

  signIn(data: any = {}): Promise<User> {
    this.username = data.username;
    this.password = data.password;
    return this.logIn();
  }

  logout() {
    return User.logOut();
  }

  recoverPassword(email: string) {
    return User.requestPasswordReset(email);
  }

  loginViaFacebook(): Promise<User> {
    return new Promise((resolve, reject) => {
      (Parse.FacebookUtils.logIn(null) as any)
      .then((user: User) => resolve(user), (err: any) => reject(err));
    });
  }

  loginWith(provider: string, authData: any = {}): Promise<User> {
    const user: any = new User;
    return user._linkWith(provider, authData);
  }

  isFacebookLinked(): boolean {
    return Parse.FacebookUtils.isLinked(Parse.User.current());
  }

  linkFacebook(authData: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.link(Parse.User.current(), authData, {
        success: (res: any) => resolve(res), error: (err: any) => reject(err)
      });
    });
  }

  unlinkFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      Parse.FacebookUtils.unlink(Parse.User.current(), {
        success: (res: any) => resolve(res), error: (err: any) => reject(err)
      });
    });
  }

  addContact(username: string) {
    return Parse.Cloud.run('addContact', { username: username });
  }

  deleteContact(username: string) {
    return Parse.Cloud.run('deleteContact', { username: username });
  }

  addBlocked(username: string) {
    return Parse.Cloud.run('addBlocked', { username: username });
  }

  deleteBlocked(username: string) {
    return Parse.Cloud.run('deleteBlocked', { username: username });
  }

  upgradeProPlan(tokenId: string, planId: string) {
    return Parse.Cloud.run('upgradeProPlan', { tokenId: tokenId, plan: planId });
  }

  payOneTime(tokenId: string, amount: number, payDescription: string) {
    return Parse.Cloud.run('payOneTime', { tokenId: tokenId, amount: amount, payDescription: payDescription });
  }

  cancelProPlan() {
    return Parse.Cloud.run('cancelProPlan');
  }

  getPlacesOfUser(username: string) {
    return Parse.Cloud.run('getPlacesOfUser', { username: username });
  }

  loadContacts(params: any = {}): Promise<User[]> {

    const page = params.page || 0;
    const limit = params.limit || 500;

    const user = Parse.User.current();

    const contacts = user.relation('contacts').query();

    contacts.skip(page * limit);
    contacts.limit(limit);

    return contacts.find() as Promise<User[]>;
  }

  loadBlocked(params: any = {}): Promise<User[]> {

    const page = params.page || 0;
    const limit = params.limit || 500;

    const user = Parse.User.current();

    const blocked = user.relation('blocked').query();

    blocked.skip(page * limit);
    blocked.limit(limit);

    return blocked.find() as Promise<User[]>;
  }

  get name(): string {
    return this.get('name');
  }

  set name(val) {
    this.set('name', val);
  }

  get email(): string {
    return this.get('email');
  }

  set email(val) {
    this.set('email', val);
  }

  get username(): string {
    return this.get('username');
  }

  set username(val) {
    this.set('username', val);
  }

  get password(): string {
    return this.get('password');
  }

  set password(val) {
    this.set('password', val);
  }

  get photo(): any {
    return this.get('photo');
  }

  set photo(val) {
    this.set('photo', val);
  }

  get authData(): any {
    return this.get('authData');
  }

  set authData(val) {
    this.set('authData', val);
  }

  get dob(): any {
    return this.get('dob');
  }

  set dob(val) {
    this.set('dob', val);
  }

  get isCompany(): any {
    return this.get('isCompany');
  }

  set isCompany(val) {
    this.set('isCompany', val);
  }

  get companyName(): any {
    return this.get('companyName');
  }

  set companyName(val) {
    this.set('companyName', val);
  }

  get companyAddress(): any {
    return this.get('companyAddress');
  }

  set companyAddress(val) {
    this.set('companyAddress', val);
  }

  get companyVat(): any {
    return this.get('companyVat');
  }

  set companyVat(val) {
    this.set('companyVat', val);
  }

  get proPlan(): any {
    return this.get('proPlan');
  }

  get bankAccount(): any {
    return this.get('bankAccount');
  }

  set bankAccount(val) {
    this.set('bankAccount', val);
  }
}

Parse.Object.registerSubclass('_User', User);
