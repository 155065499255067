import { Injectable } from '@angular/core';
import { Platform, Events } from '@ionic/angular';
import { OneSignal, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const ONESIGNAL_APP_ID = environment.oneSignalAppId;
const GOOGLE_PROJECT_ID = environment.googleProjectNumber;

declare global {
  interface Window { OneSignal: OneSignal; }
}

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  private oneSignalInstance: any;

  private initialized: boolean = false;

  private WebPushSDK: any = {
    loaded: false,
    src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
  };

  constructor (
    private platform: Platform,
    private OneSignalCordova: OneSignal,
    private events: Events) {
    this.oneSignalInstance = this.OneSignalCordova;
    this.init();
  }

  async instance() {
    if (!this.initialized || !this.oneSignalInstance) {
      await this.init();
    }
    return this.oneSignalInstance;
  }

  async init() {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return this._initBrowser();
    }
    return this._initCordova();
  }

  public sendTag(key: string, value: string) {
    this.oneSignalInstance.sendTag(key, value);
    if (this.WebPushSDK.loaded) {
      window.OneSignal.sendTag(key, value);
    }
  }

  public deleteTag(key: string) {
    this.oneSignalInstance.deleteTag(key);
    if (this.WebPushSDK.loaded) {
      window.OneSignal.deleteTag(key);
    }
  }

  private _initBrowser() {
    return new Promise((resolve, reject) => {
      if (this.initialized === true) {
        return resolve(this);
      }

      this.platform.ready().then(() => {
        // El plugin de Cordova se inyecta en `window['OneSignal']`. Lo eliminamos.
        delete window.OneSignal;

        this.loadWebPushSDK().then(() => {
          // El SDK de WebPush ya ha sido cargado y ahora debemos inicializarlo 
          // normalmente.

          this.oneSignalInstance = window.OneSignal || [];

          this.oneSignalInstance.push(() => {
            this.oneSignalInstance.init({
              appId: ONESIGNAL_APP_ID,
            });
          });

          this.initialized = true;

          return resolve(this);
        }, (errors: any) => {
          reject(errors);
        });
      }, (errors: any) => {
        reject(errors);
      });
    });
  }

  private _initCordova() {
    return new Promise((resolve, reject) => {
      if (this.initialized === true) {
        return resolve(this);
      }

      this.platform.ready().then(() => {
        this.oneSignalInstance.startInit(ONESIGNAL_APP_ID, GOOGLE_PROJECT_ID);

        this.oneSignalInstance.inFocusDisplaying(this.oneSignalInstance.OSInFocusDisplayOption.Notification);
    
        this.oneSignalInstance.handleNotificationReceived().subscribe((data: OSNotificationOpenedResult) => {
          // do something when notification is received
          console.log('handleNotificationReceived data',data);
          this.events.publish('notification:received', data);
        });
    
        this.oneSignalInstance.handleNotificationOpened().subscribe((data: OSNotificationOpenedResult) => {
          // do something when a notification is opened
          this.events.publish('notification:opened', data);
        });

        this.oneSignalInstance.endInit();

        this.initialized = true;

        return resolve(this);
      });
    });
  }

  /**
   * Source: `https://stackoverflow.com/a/42766146/2522130`.
   */
  loadWebPushSDK() {
    return new Promise((resolve, reject) => {
      if (this.WebPushSDK.loaded) {
        resolve(true);
      } else {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.WebPushSDK.src;

        script.onload = () => {
          this.WebPushSDK.loaded = true;

          resolve(true);
        };

        script.onerror = () => {
          reject(false);
        };

        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}