import { Component, OnInit, Input } from '@angular/core';
import { Place } from 'src/app/services/place-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-place-header',
  templateUrl: './place-header.component.html',
  styleUrls: ['./place-header.component.scss'],
})
export class PlaceHeaderComponent implements OnInit {

  @Input() place: Place;
  @Input() location: any;
  @Input() showDescription: boolean = true;
  @Input() unit = 'km';

  constructor() { }

  ngOnInit() {}

}
