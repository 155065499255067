import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import tinycolor from 'tinycolor2';

export interface IonColor {
  key: string;
  value: string;
  friendlyName: string;
};

@Injectable({
  providedIn: 'root'
})
export class NotificationColorService {

  private ionPrefix: string = ".ion-color-";

  public colorList: IonColor[] = [
    { key: "yellow", value: "rgba(255,185,0,0.5)", friendlyName: "Yellow" },
    { key: "orange", value: "rgba(255,140,0,0.5)", friendlyName: "Orange" },
    { key: "green", value: "rgba(16,137,62,0.5)", friendlyName: "Green" },
    { key: "blue", value: "rgba(0,120,215,0.5)", friendlyName: "Blue" },
    { key: "red", value: "rgba(232,17,35,0.5)", friendlyName: "Red" }
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.colorList.forEach(c => this.addIonColor(c.key, c.value));
  }

  public getColorValue(colorKey: string): string {
    let idx = this.colorList.map(c => c.key).indexOf(colorKey);
    return idx == -1 ? undefined : this.colorList[idx].value;
  }

  public addIonColor(name: string, baseColor: string) {
    const namePattern = /^[a-zA-Z][\-_0-9A-Za-z]+$/;

    if (!namePattern.test(name)) {
      throw new Error(`Invalid color name: ${name} should match /^[a-zA-Z][\-_0-9A-Za-z]$/`);
    }
    let color = new tinycolor(baseColor);

    if (!color.isValid()) {
      throw new Error(`Invalid color value: ${baseColor}`);
    }
    let hex = color.toString('hex6');
    let rgb = color.toRgb();
    let contrast = tinycolor(color.getBrightness() > 150 ? "#222" : "#eee");
    let contrastRgb = contrast.toRgb();

    let css =
      `${this.ionPrefix + name} {
        --ion-color-base: ${hex};
        --ion-color-base-rgb: ${rgb.r},${rgb.g},${rgb.b};
        --ion-color-contrast: ${contrast.toString('hex6')};
        --ion-color-contrast-rgb: ${contrastRgb.r},${contrastRgb.g},${contrastRgb.b};
        --ion-color-shade: ${color.darken().toString('hex6')};
        --ion-color-tint: ${color.lighten().toString('hex6')};
      }
    `

    var docStyle = this.document.createElement('style');
    docStyle.type = 'text/css';
    docStyle.innerHTML = css;
    this.document.getElementsByTagName('head')[0].appendChild(docStyle);
  }

}