import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { User } from './user-service';

@Injectable({
  providedIn: 'root'
})
export class Place extends Parse.Object {

  constructor() {
    super('Place');
  }

  static getInstance() {
    return this;
  }

  getSlug(): string {
    let slug = '1/places/' + this.id;

    if (this.slug) {
      slug += '/' + this.slug;
    }

    return slug;
  }

  distance(location: Parse.GeoPoint, unit: string = 'km') {

    if (!location) { return null; }

    let geoPoint = new Parse.GeoPoint({
      latitude: location.latitude,
      longitude: location.longitude
    });

    if (unit === 'km') {
      return this.location.kilometersTo(geoPoint).toFixed(1) + ' ' + unit;
    } else {
      return this.location.milesTo(geoPoint).toFixed(1) + ' ' + unit;
    }
  }

  qrEmail(place: Place) {
    return Parse.Cloud.run('sendQrEmail', { placeId: place.id });
  }

  enablePlace(place: Place, user: User) {
    return Parse.Cloud.run('enablePlace', { placeId: place.id, userId: user.id });
  }

  privateLikePlace(place: Place){
    return Parse.Cloud.run('privateLikePlace', { placeId: place.id });
  }

  updatePendingUser(place: Place){
    return Parse.Cloud.run('updatePendingUser', { placeId: place.id, pendingUser: place.pendingUser });
  }

  updateAcceptedUser(place: Place){
    console.log('updateAcceptedUser');
    
    return Parse.Cloud.run('updateAcceptedUser', { placeId: place.id, acceptedUser: place.acceptedUser });
  }

  deleteUserFromAcceptedUsers(place: Place, userId: string){
    return Parse.Cloud.run('deleteUserFromAcceptedUsers', { placeId: place.id, userId: userId });
  }

  like(place: Place) {
    return Parse.Cloud.run('likePlace', { placeId: place.id });
  }

  sendNotification(userId:string, title: string, body: string, placeId: string) {
    return Parse.Cloud.run('sendNotification', { userId: userId, title: title, body: body, placeId: placeId });
  }

  removeLike(place: Place, user: User) {
    return Parse.Cloud.run('removeLike', { placeId: place.id, userId: user.id });
  }

  addModerator(place: Place, username: string) {
    return Parse.Cloud.run('addModerator', { placeId: place.id, username: username });
  }

  deleteModerator(place: Place, username: string) {
    return Parse.Cloud.run('deleteModerator', { placeId: place.id, username: username });
  }

  isModerator(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceModerator', { placeId: place.id });
  }

  isLiked(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceLiked', { placeId: place.id });
  }

  getLikes(place: Place): Promise<User[]> {
    return place.relation('likes').query().find() as Promise<User[]>;
  }

  getModerators(place: Place): Promise<User[]> {
    return place.relation('moderators').query().find() as Promise<User[]>;
  }

  isStarred(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceStarred', { placeId: place.id });
  }

  delete(place: Place) {
    return Parse.Cloud.run('deletePlace', { placeId: place.id });
  }

  deleteEND(place: Place) {
    return Parse.Cloud.run('deletePlaceEND', { placeId: place.id });
  }
  
  loadOne(id: string): Promise<Place> {
    const query = new Parse.Query(Place);
    // query.equalTo('status', 'Approved');
    // query.equalTo('status', 'Expired');
    query.include('category');
    query.include('user');
    return query.get(id);
  }

  load(params: any = {}): Promise<Place[]> {
    console.log('params',params)
    const page = params.page || 0;
    const limit = params.limit || 100;
    const distance = params.distance || 100;
    const status = params.status || 'Approved';
    const loadPrivate = params.loadPrivate || false;
    const loadModerators = params.loadModerators || false;

    const query = new Parse.Query(Place);

    if (!loadPrivate) {
      query.notEqualTo('isPrivate', true);
    }

    if (Array.isArray(status)) {
      query.containedIn('status', status);
    } else {
      query.equalTo('status', status);
    }

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.featured) {
      query.equalTo('isFeatured', true);
    }

    if (params.user) {
      query.equalTo('user', params.user);
    }

    if (params.canonical && params.canonical !== '') {
      query.contains('canonical', params.canonical);
    }

    if (params.bounds) {

      const southwest = new Parse.GeoPoint(
        params.bounds[0].latitude,
        params.bounds[0].longitude
      );

      const northeast = new Parse.GeoPoint(
        params.bounds[1].latitude,
        params.bounds[1].longitude
      );

      query.withinGeoBox('location', southwest, northeast);

    } else if (params.location) {

      const point = new Parse.GeoPoint({
        latitude: params.location.latitude,
        longitude: params.location.longitude
      });

      if (params.unit && params.unit === 'km') {
        query.withinKilometers('location', point, distance);
      } else {
        query.withinMiles('location', point, distance);
      }

    } else {
      query.descending('createdAt');
    }

    query.skip(page * limit);
    query.limit(limit);

    query.include('category');
    query.doesNotExist('deletedAt');

    if (params.loadModerators) {
      query.include('moderators');
      const _self = this;
      return query.find().then(function (places) {
        places.forEach(async place => {
          place.moderators = await _self.getModerators(place);
        });
        return places;
      });
    } else {
      return query.find();
    }
  }

  async reloadPlace(place:Place){
    const query = new Parse.Query(Place);
    return await query.get(place.id)
  }

  loadFavorites(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;

    const query = new Parse.Query(Place);
    query.equalTo('status', 'Approved');
    query.equalTo('likes', Parse.User.current());

    query.skip(page * limit);
    query.limit(limit);
    query.include('category');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  loadModerated(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;

    const query = new Parse.Query(Place);
    query.equalTo('status', 'Approved');
    query.equalTo('moderators', Parse.User.current());

    query.skip(page * limit);
    query.limit(limit);
    query.include('category');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  getPoster(){
    const aux = this.get('status')
    if(aux === 'Expired'){
      return 'https://apiv2-eu.yacubo.com/assets/imgs/cubo-offline.png'
    }else{
      return this.get('image').url()
    }
  }

  get title(): string {
    return this.get('title');
  }

  set title(val) {
    this.set('title', val);
  }

  get description(): string {
    return this.get('description');
  }

  set description(val) {
    this.set('description', val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get website(): string {
    return this.get('website');
  }

  set website(val) {
    this.set('website', val);
  }

  get isPrivate(): boolean {
    return this.get('isPrivate');
  }

  set isPrivate(val) {
    this.set('isPrivate', val);
  }

  get directEnabled(): string {
    return this.get('directEnabled');
  }

  set directEnabled(val) {
    this.set('directEnabled', val);
  }

  get isSuperPlace(): string {
    return this.get('isSuperPlace');
  }

  get welcomeNotification(): string {
    return this.get('welcomeNotification');
  }

  set welcomeNotification(val) {
    this.set('welcomeNotification', val);
  }

  get address(): string {
    return this.get('address');
  }

  set address(val) {
    this.set('address', val);
  }

  get category() {
    return this.get('category');
  }

  set category(val) {
    this.set('category', val);
  }

  get image() {
    return this.get('image');
  }

  set image(val) {
    this.set('image', val);
  }

  get images() {
    return this.get('images');
  }

  set images(val) {
    this.set('images', val);
  }

  get location() {
    return this.get('location');
  }

  set location(val) {
    let geoPoint = new Parse.GeoPoint({
      latitude: val.lat,
      longitude: val.lng
    });
    this.set('location', geoPoint);
  }

  get imageTwo() {
    return this.get('imageTwo');
  }

  get imageThree() {
    return this.get('imageThree');
  }

  get imageFour() {
    return this.get('imageFour');
  }

  get imageThumb() {
    return this.get('imageThumb');
  }

  get ratingCount() {
    return this.get('ratingCount');
  }

  get ratingTotal() {
    return this.get('ratingTotal');
  }

  get status() {
    return this.get('status');
  }

  get facebook() {
    return this.get('facebook');
  }

  get youtube() {
    return this.get('youtube');
  }

  get instagram() {
    return this.get('instagram');
  }

  get longDescription() {
    return this.get('longDescription');
  }

  get slug() {
    return this.get('slug');
  }

  get rating() {
    if (!this.ratingCount && !this.ratingTotal) { return 0; }
    return Math.round(this.ratingTotal / this.ratingCount);
  }

  get user() {
    return this.get('user');
  }

  get likeCount() {
    return this.get('likeCount');
  }

  set moderators(val) {
    this.set('moderators', val);
  }

  get moderators() {
    return this.get('moderators');
  }
  
  get acceptedUser() {
    return this.get('acceptedUser');
  }

  set acceptedUser(val) {
    this.set('acceptedUser', val);
  }

  get pendingUser() {
    return this.get('pendingUser');
  }

  set pendingUser(val) {
    this.set('pendingUser', val);
  }

  get rejectUser() {
    return this.get('rejectUser');
  }

  set rejectUser(val) {
    this.set('rejectUser', val);
  }

  get isCapacityAndPayment() {
    return this.get('isCapacityAndPayment');
  }

  set isCapacityAndPayment(val) {
    this.set('isCapacityAndPayment', val);
  }

  get capacity() {
    return this.get('capacity');
  }

  set capacity(val) {
    this.set('capacity', val);
  }

  get price() {
    return this.get('price');
  }

  set price(val) {
    this.set('price', val);
  }


  get dob() {
    return this.get('dob');
  }

  set dob(val) {
    this.set('dob', val);
  }

  get timeToClose() {
    return this.get('timeToClose');
  }

  set timeToClose(val) {
    this.set('timeToClose', val);
  }

  get purchaseBlock() {
    let aux = this.get('purchaseBlock');
    if(aux === undefined || aux === null){
      aux = false
    }
    return aux
  }

  set purchaseBlock(val:boolean) {
    this.set('purchaseBlock', val);
  }

  get verified() {
    if(this.get('verified') === true){
      return true
    }else{
      return false;
    }
  }

  get imageToShow(){
    const aux = this.get('imageToShow');
    if(aux === undefined || aux === ""){
      return this.get('image').url();
    }
    return aux
    // 'https://apiv2-eu.yacubo.com/assets/imgs/cubo-offline.png'
  }

  set imageToShow(val:string){
    this.set('imageToShow', val);
  }
}

Parse.Object.registerSubclass('Place', Place);
